import React, {useState} from "react";
import Datatable from "./datatable";
import axios from "axios";
import HeaderImage from "../assets/Header.png"

const measurements = ['damage-taken', 'damage-done', 'healing'];

const supportBuffs = [
    {name: "FF", id: 9907, render: true},
    {name: "CoR", id: 11717, render: true},
    {name: "CoE", id: 11722, render: true},
    {name: "IEA", id: 11198, render: true},
    {name: "MC", id: 10912, render: false}
];

const client = axios.create({
    baseURL: 'https://www.warcraftlogs.com:443/v1',
    params: {
        "translate": false,
        "api_key": process.env.REACT_APP_API_KEY
    }
});

function getFight(code) {
    return client.get(`/report/fights/${code}`);
}

function getTable(code, measurement, start = 0, end = 0) {
    return client.get(`/report/tables/${measurement}/${code}`, {
        params: {start: start, end: end}
    });
}

function getSpecificAbility(code, ability, start = 0, end = 0) {
    return client.get(`/report/tables/casts/${code}`, {
        params: {start: start, end: end, abilityid: ability.id, encounter: -2}
    });
}

function LoadFight() {
    const [results, setResults] = useState([]);
    const [characters, setCharacters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportLink, setReportLink] = useState("");
    const [reportCode, setReportCode] = useState("");

    function extractReportCode(url) {
        const match = url.match(/\/reports\/([a-zA-Z0-9]+)/);
        return match ? match[1] : "";
    }

    function handleInputChange(event) {
        const url = event.target.value;
        setReportLink(url);
        const code = extractReportCode(url);
        setReportCode(code);
    }

    function loadFight() {
        setLoading(true);
        getFight(reportCode).then(res => {
            setCharacters(res.data.friendlies);

            const promises = measurements.map(m =>
                getTable(reportCode, m, 0, res.data.end)
                    .then(res => ({
                        measurement: m,
                        data: res.data.entries
                            .sort((a, b) => b.total - a.total)
                            .slice(0, m === 'healing' ? 8 : m === 'damage-done' ? 12 : 6)
                            .map(entry => ({
                                name: entry.name,
                                total: entry.total
                            }))
                    }))
            );

            const supportPromises = supportBuffs.map(buff =>
                getSpecificAbility(reportCode, buff, 0, res.data.end)
                    .then(res => {
                        let updatedData = res.data.entries.map(entry => ({
                            name: entry.name,
                            total: entry.total,
                            uptimePercentage: ((entry.uptime / res.data.totalTime) * 100).toFixed(2),
                        }));
                        if (buff.name === 'MC') {
                            updatedData = updatedData
                                .sort((a, b) => b.total - a.total)
                                .slice(0, 3);
                        }
                        if (buff.name === 'CoR' || buff.name === 'CoE' || buff.name === 'FF') {
                            updatedData = updatedData
                                .sort((a, b) => b.total - a.total)
                                .slice(0, 1);
                        }
                        return {
                            measurement: buff.name,
                            data: updatedData,
                            render: buff.render
                        };
                    })
            );


            Promise.all([...promises, ...supportPromises]).then(results => {
                const formattedResults = results.map(result => ({
                    measurement: result.measurement,
                    data: result.data.sort(compareByTotal),
                    render: result.render ?? true
                }));
                setResults(formattedResults);
                setLoading(false);
            });
        }).catch(err => {
            console.log('Error:', err);
            setLoading(false);
        });
    }

    function compareByTotal(a, b) {
        return b.total - a.total;
    }

    return (
        <div className="bg-slate-100">
            <header className="flex flex-col items-center justify-center min-h-screen">
                <div className="flex items-center space-x-4">
                    <a href="/">
                        <img src={HeaderImage} alt="Header" className="flex-grow max-h-40 object-contain"/>
                    </a>
                    <div className="flex flex-col items-center space-y-4">
                        <input
                            type="text"
                            className="bg-zinc-100 hover:bg-slate-50 mb-4 p-2 border border-black rounded w-96 shadow-md hover:shadow-lg transition-shadow duration-30"
                            placeholder="Insert WarcraftLogs URL"
                            value={reportLink}
                            onChange={handleInputChange}
                        />
                        <button
                            className="bg-zinc-200 hover:bg-zinc-300 text-black font-semibold py-2 px-4 rounded border border-black mt-3.5 shadow-md hover:shadow-lg transition-shadow duration-30"
                            onClick={loadFight}
                            disabled={!reportCode}
                        >
                            Analyze Logs
                        </button>
                    </div>
                    <a href="/">
                        <img src={HeaderImage} alt="Header" className="flex-grow max-h-40 object-contain"/>
                    </a>
                </div>
                {results.length > 0 && characters.length > 0 && !loading && (
                    <Datatable data={results} characters={characters}/>
                )}
            </header>
        </div>
    );
}

export default LoadFight;