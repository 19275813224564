import React from "react";

function Footer() {
    return (
        <footer className="bg-slate-100 p-6 mt-4 shadow-md">
            <div className="container mx-auto text-center ">
                <p className="text-black font-semibold">© 2024 Kat. All rights reserved.</p>
                <div className="flex justify-center space-x-4 mt-2">
                    <a href="https://x.com/FredKat_"
                       className="text-black hover:text-blue-500 transition-colors duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="currentColor"
                             viewBox="0 0 24 24">
                            <path
                                d="M22.46 6.03c-.77.35-1.6.58-2.47.68a4.26 4.26 0 0 0 1.85-2.35c-.82.48-1.72.82-2.69 1.01a4.24 4.24 0 0 0-7.22 3.87A12.02 12.02 0 0 1 1.64 4.15a4.24 4.24 0 0 0 1.31 5.66A4.22 4.22 0 0 1 1.64 9v.05a4.24 4.24 0 0 0 3.4 4.15 4.24 4.24 0 0 1-1.92.07 4.24 4.24 0 0 0 3.96 2.94A8.51 8.51 0 0 1 0 20.54a12.01 12.01 0 0 0 6.51 1.91c7.81 0 12.08-6.47 12.08-12.08 0-.18-.01-.36-.02-.54.82-.6 1.52-1.34 2.08-2.19z"/>
                        </svg>
                    </a>
                    <a href="https://github.com/Fred-Pi"
                       className="text-black hover:text-blue-500 transition-colors duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="currentColor"
                             viewBox="0 0 24 24">
                            <path
                                d="M12 2.16c-5.44 0-9.84 4.4-9.84 9.84 0 4.34 2.82 8.03 6.72 9.35.49.09.67-.21.67-.47v-1.73c-2.73.6-3.29-1.33-3.29-1.33-.45-1.14-1.12-1.44-1.12-1.44-.91-.62.07-.6.07-.6 1.01.07 1.54 1.04 1.54 1.04.89 1.53 2.34 1.09 2.91.83.09-.65.35-1.09.64-1.34-2.18-.25-4.48-1.09-4.48-4.85 0-1.07.38-1.95 1.02-2.63-.1-.25-.45-1.27.1-2.64 0 0 .83-.27 2.72 1.02a9.42 9.42 0 0 1 2.48-.33c.84.01 1.68.11 2.48.33 1.89-1.29 2.72-1.02 2.72-1.02.55 1.37.2 2.39.1 2.64.63.68 1.02 1.56 1.02 2.63 0 3.77-2.3 4.6-4.48 4.85.35.3.68.88.68 1.78v2.64c0 .26.18.56.68.47 3.9-1.32 6.72-5.01 6.72-9.35 0-5.44-4.4-9.84-9.84-9.84z"/>
                        </svg>
                    </a>
                </div>
                <div className="mt-4">
                    <p className="text-black font-semibold">Contact: <a href="mailto:f.red.innovations@gmail.com"
                                                                        className="text-blue-500 hover:underline">f.red.innovations@gmail.com</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
