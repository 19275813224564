import LoadFight from "./components/LoadFight";
import Footer from "./components/Footer";

function App() {
    return (
        <>
            <LoadFight />
            <Footer />
        </>
    )
}

export default App;
