import React, { useEffect, useMemo, useRef, useState } from "react";
import { toPng } from "html-to-image";

function Datatable({ data, characters }) {
    const tableRef = useRef();
    const [hunters, setHunters] = useState([]);
    const [selectedHunter, setSelectedHunter] = useState('');
    const [paladins, setPaladins] = useState([]);
    const [selectedPaladin, setSelectedPaladin] = useState('');
    const [priests, setPriests] = useState([]);
    const [selectedMC, setSelectedMC] = useState([]);
    const [totalPot, setTotalPot] = useState(400000);
    const [cuts, setCuts] = useState([]);
    const [selectedCuts, setSelectedCuts] = useState({});
    const [totalCuts, setTotalCuts] = useState([]);
    const [popupMessage, setPopupMessage] = useState(null);
    const [totalsAreFilled, setTotalsAreFilled] = useState();
    const [deductions, setDeductions] = useState([{ name: '', percentage: '', newCut: '' }]);
    const [startingPercentages] = useState({
        "damage-taken": [1.25, 1.25, 1.00, 0.75, 0.40, 0.30],
        "damage-done": [1.25, 1.00, 0.85, 0.75, 0.65, 0.55, 0.50, 0.45, 0.40, 0.35, 0.30, 0.25],
        "healing": [0.75, 0.65, 0.55, 0.45, 0.35, 0.25, 0.15, 0.15],
        "FF": [0.4],
        "CoR": [0.4],
        "CoE": [0.4],
        "IEA": [0.5],
        "MC": [0.35, 0.30, 0],
        "Hunter": [0.30],
        "Paladin": [0.30],
        "Top Spender": [0.50]
    });

    useMemo(() => {
        characters.forEach(c => addPlayer(c));
        wipeCuts();
        const foundPriests = characters
            .filter(character => character.type === 'Priest')
            .sort((a, b) => a.name.localeCompare(b.name));
        const foundHunters = characters.filter(character => character.type === 'Hunter');
        const foundPaladins = characters.filter(character => character.type === 'Paladin');
        setHunters(foundHunters);
        setSelectedHunter(foundHunters[0] ?? '');
        setPaladins(foundPaladins);
        setSelectedPaladin(foundPaladins[0] ?? '');
        setPriests(foundPriests);
        const mcCategory = data.find(category => category.measurement === "MC");
        const defaultPriest = foundPriests
            .filter(priest => mcCategory.data.some(entry => entry.name === priest.name))
            .splice(0, 3)
        setSelectedMC(defaultPriest);
        setTotalCuts(Object.values(Object.keys(startingPercentages).map(key => ({ name: key, entries: [] }))))
    }, [characters]);

    const calculateSupportBonuses = () => {
        return cuts.reduce((total, player) => total + player.bonuses, 0);
    };

    const calculateBaseCut = (cutPercentage) => {
        const organizerShare = totalPot * 0.15;
        const supportBonuses = calculateSupportBonuses();
        const remainingPot = totalPot - organizerShare - supportBonuses;
        const baseCut = (cutPercentage / 100) * remainingPot;
        return baseCut;
    };

    const calculateTotalCut = (cutPercentage, adjustments = 0) => {
        const baseCut = calculateBaseCut(cutPercentage);
        const finalCut = baseCut + adjustments;
        return finalCut.toFixed(0);
    };

    function addPlayer(player) {
        if (['NPC', 'Pet'].includes(player.type)) return;
        if (cuts.find(c => c.name === player.name)) return;
        setCuts(players => [...players, { name: player.name, base: 0, bonuses: 0 }])
    }

    function addCut(name, bonus) {
        let foundPlayer = cuts.find(p => p.name === name);
        if (foundPlayer) {
            setCuts(cuts.map(player => player.name === name ? {
                name: player.name,
                base: player.base,
                bonuses: player.bonuses += parseInt(bonus)
            } : player));
        }
    }

    function findCut(type, index) {
        const foundCut = totalCuts.find(cut => cut.name === type);
        console.log(type, index, foundCut);
        return (foundCut) ? foundCut?.['entries']?.[index] : 0;
    }

    function wipeCuts() {
        setCuts(prevState => prevState.map(player => ({ name: player.name, base: 0, bonuses: 0 })));
    }

    const handleCutChange = (event, entryIndex, category) => {
        wipeCuts();
        const { value } = event.target;
        const cutPercentage = parseFloat(value);
        console.log('handleCutChangePercentage: ', cutPercentage);

        setSelectedCuts(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [entryIndex]: cutPercentage
            }
        }));

        // Update Top Spender Cut
        if (category === 'Top Spender') {
            setTotalCuts(prevState => {
                const newTotalCuts = [...prevState];
                const foundIndex = newTotalCuts.findIndex(row => row.name === category);
                if (foundIndex !== -1) {
                    const newEntries = newTotalCuts[foundIndex].entries;
                    newEntries[entryIndex] = calculateTotalCut(cutPercentage);

                    newTotalCuts[foundIndex] = {
                        ...newTotalCuts[foundIndex],
                        entries: newEntries
                    };
                }
                return newTotalCuts;
            });
        }
    };

    function addBonusToPlayer(player, type, index) {
        const cutPercentage = selectedCuts[type]?.[index] || startingPercentages[type][index];
        console.log('foundCutPercentage', cutPercentage);
        const foundIndex = totalCuts.findIndex(row => row.name === type);
        setTotalCuts(prevState => {
            const newTotalCuts = [...prevState];
            if (foundIndex !== -1) {
                const newEntries = newTotalCuts[foundIndex].entries;
                newEntries[index] = calculateTotalCut(cutPercentage);

                newTotalCuts[foundIndex] = {
                    ...newTotalCuts[foundIndex],
                    entries: newEntries
                };
            }
            return newTotalCuts;
        });
        addCut(player, calculateTotalCut(cutPercentage));
        console.log('Cuts after addCut:', cuts);
    }

    const handleCopyToClipboard = () => {
        console.log('handleCopyToClipboard called');
        console.log('Current cuts:', cuts);

        const formattedCuts = calculateFinalCuts().map(player => `${player.name}, ${player.finalCut || player.cut}`).join('\n');
        console.log('Formatted cuts:', formattedCuts);

        navigator.clipboard.writeText(formattedCuts)
            .then(() => {
                setPopupMessage('Export successful!');
                setTimeout(() => setPopupMessage(null), 3000);
            })
            .catch(err => {
                setPopupMessage('Error exporting data');
                setTimeout(() => setPopupMessage(null), 3000);
                console.error('Error exporting data', err);
            });
    }

    const handleExportToPNG = () => {
        if (tableRef.current) {
            toPng(tableRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'table.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((err) => {
                    console.error('Error exporting table to PNG', err);
                });
        }
    };

    const handleSelect = (event, index, type) => {
        const { value } = event.target;
        if (type === 'MC') {
            setSelectedMC(prevState => {
                const updatedMC = [...prevState];
                updatedMC[index] = priests.find(priest => priest.name === value);
                return updatedMC;
            });
        } else if (type === 'Hunter') {
            setSelectedHunter(value);
        } else if (type === 'Paladin') {
            setSelectedPaladin(value);
        }
    };

    const handleAddDeduction = () => {
        setDeductions(prevDeductions => [...prevDeductions, { name: '', percentage: '', newCut: '' }]);
    };

    const handleDeductionChange = (index, field, value) => {
        const updatedDeductions = [...deductions];
        updatedDeductions[index][field] = value;

        if (field === 'percentage') {
            const percentage = parseFloat(value);
            const characterCut = cuts.find(cut => cut.name === updatedDeductions[index].name);
            if (characterCut) {
                const baseCut = calculateBaseCut(startingPercentages["damage-done"][0]);
                const bonuses = parseFloat(characterCut.bonuses);
                const totalCut = baseCut + bonuses;
                updatedDeductions[index]['newCut'] = ((percentage / 100) * totalCut).toFixed(0);
            } else {
                updatedDeductions[index]['newCut'] = '0';
            }
        }

        setDeductions(updatedDeductions);
    };

    const handleTotalPotChange = (event) => {
        setTotalPot(parseFloat(event.target.value));
        wipeCuts();
    };

    const calculateFinalCuts = () => {
        return cuts.map(player => {
            const deduction = deductions.find(d => d.name === player.name);
            const deductionPercentage = deduction ? parseFloat(deduction.percentage) || 0 : 0;
            const baseCut = calculateBaseCut(startingPercentages["damage-done"][0]);
            let totalBonuses = player.bonuses;

            if (player.name === selectedPaladin) {
                totalBonuses += parseFloat(findCut('Paladin', 0));
            }
            if (player.name === deductions[0]?.name) {
                totalBonuses += parseFloat(findCut('Top Spender', 0));
            }

            const totalCutBeforeDeduction = baseCut + totalBonuses;
            const deductionAmount = (deductionPercentage / 100) * totalCutBeforeDeduction;
            const finalCut = totalCutBeforeDeduction - deductionAmount;

            return {
                ...player,
                base: baseCut.toFixed(0),
                finalCut: finalCut.toFixed(0)
            };
        }).sort((a, b) => a.name.localeCompare(b.name));
    };

    useEffect(() => {
        data.forEach((category, categoryIndex) => {
            if (!category.render) return;
            category.data.forEach((entry, entryIndex) => {
                addBonusToPlayer(entry.name, category.measurement, entryIndex);
            });
        });
        const manualCuts = [selectedHunter, ...selectedMC, selectedPaladin, deductions[0]?.name].map((row, index) => {
            return { name: row.name, type: (index === 0) ? 'Hunter' : (index <= 3) ? 'MC' : (index === 4) ? 'Paladin' : 'Top Spender' };
        });
        manualCuts
            .filter(row => row.type === 'Hunter')
            .forEach((row, index) => addBonusToPlayer(row.name, row.type, index));
        manualCuts
            .filter(row => row.type === 'MC')
            .forEach((row, index) => addBonusToPlayer(row.name, row.type, index));
        manualCuts
            .filter(row => row.type === 'Paladin')
            .forEach((row, index) => addBonusToPlayer(row.name, row.type, index));
        manualCuts
            .filter(row => row.type === 'Top Spender')
            .forEach((row, index) => addBonusToPlayer(row.name, row.type, index));
        setTotalsAreFilled(true);
        console.log('Cuts after calculations:', cuts);
    }, [totalPot, selectedCuts]);

    useEffect(() => {
        if (deductions[0]?.name) {
            addBonusToPlayer(deductions[0]?.name, 'Top Spender', 0);
        }
    }, [deductions]);

    return (
        <div className="data-table grid grid-cols-9 gap-4 mx-auto p-4 shadow-md">
            <div className="col-span-9 mb-4">
                <label htmlFor="totalPot" className="mb-2 px-4 py-2 font-bold">Total Pot:</label>
                <input
                    type="number"
                    id="totalPot"
                    value={totalPot}
                    onChange={handleTotalPotChange}
                    className="border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                />
            </div>
            <div className="col-span-4">
                <table className="w-full shadow-md">
                    <thead>
                    <tr className="bg-zinc-200">
                        <th className="px-4 py-2 w-1/6">Role</th>
                        <th className="px-4 py-2 w-1/6">Name</th>
                        <th className="px-4 py-2 w-1/6">Total</th>
                        <th className="px-4 py-2 w-1/6">Uptime%</th>
                        <th className="px-4 py-2 w-1/6">Bonus Cut</th>
                        <th className="px-4 py-2 w-1/6">Total Cut</th>
                    </tr>
                    </thead>
                    {data && data.map((category, categoryIndex) => (
                        <tbody key={categoryIndex} className="category-table mb-8">
                        {category.data && totalsAreFilled === true && category.render && category.data.map((entry, entryIndex) => (
                            <tr key={`generated-${entryIndex}`}
                                className={entryIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                <td className="border px-4 py-2 font-bold">{getRankLabel(category.measurement, entryIndex)}</td>
                                <td className="border px-4 py-2">
                                    <span>{entry.name}</span>
                                </td>
                                <td className="border px-4 py-2">{entry.total}</td>
                                <td className="border px-4 py-2">{entry.uptimePercentage}</td>
                                <td className="border px-4 py-2">
                                    <input
                                        type="number"
                                        className="block w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                        min="0.0"
                                        max="1.5"
                                        step="0.05"
                                        value={selectedCuts[category.measurement]?.[entryIndex] || startingPercentages[category.measurement][entryIndex]}
                                        onChange={(event) => handleCutChange(event, entryIndex, category.measurement)}
                                    />
                                </td>
                                <td className="border px-4 py-2">{findCut(category.measurement, entryIndex)}</td>
                            </tr>
                        ))}
                        </tbody>
                    ))}
                    {data.length > 0 && totalsAreFilled === true && selectedMC.length > 0 &&
                        <tbody key={"manual-rows"}>
                        <tr className={2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                            <td className="border px-4 py-2 font-bold">Hunter puller</td>
                            <td className="border px-4 py-2">
                                <select
                                    value={selectedHunter}
                                    onChange={(event) => handleSelect(event, 0, 'Hunter')}
                                    className="w-full border rounded py-2 px-2 shadow-md hover:shadow-lg transition-shadow duration-30"
                                >
                                    {hunters.map(hunter => (
                                        <option key={hunter.name} value={hunter.name}>{hunter.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td className="border px-4 py-2">&nbsp;</td>
                            <td className="border px-4 py-2">&nbsp;</td>
                            <td className="border px-4 py-2">
                                <input
                                    type="number"
                                    className="block w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                    min="0.0"
                                    max="1.5"
                                    step="0.05"
                                    value={selectedCuts['Hunter']?.[0] || startingPercentages['Hunter'][0]}
                                    onChange={(event) => handleCutChange(event, 0, 'Hunter')}
                                />
                            </td>
                            <td className="border px-4 py-2">{findCut('Hunter', 0)}</td>
                        </tr>
                        {startingPercentages['MC'].map((entry, entryIndex) => (
                            <tr key={`manual-${entryIndex}`}
                                className={entryIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                <td className="border px-4 py-2 font-bold">MC Priest {entryIndex + 1}</td>
                                <td className="border px-4 py-2">
                                    <select
                                        value={selectedMC[entryIndex]?.name}
                                        onChange={(event) => handleSelect(event, entryIndex, 'MC')}
                                        className="w-full border rounded py-2 px-2 shadow-md hover:shadow-lg transition-shadow duration-30"
                                    >
                                        {priests.map(priest => (
                                            <option key={priest.name} value={priest.name}>{priest.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td className="border px-4 py-2">&nbsp;</td>
                                <td className="border px-4 py-2">&nbsp;</td>
                                <td className="border px-4 py-2">
                                    <input
                                        type="number"
                                        className="block w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                        min="0.0"
                                        max="1.5"
                                        step="0.05"
                                        value={selectedCuts['MC']?.[entryIndex] || startingPercentages['MC'][entryIndex]}
                                        onChange={(event) => handleCutChange(event, entryIndex, 'MC')}
                                    />
                                </td>
                                <td className="border px-4 py-2">{findCut('MC', entryIndex)}</td>
                            </tr>
                        ))}
                        <tr className={2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                            <td className="border px-4 py-2 font-bold">Paladin Kiter</td>
                            <td className="border px-4 py-2">
                                <select
                                    value={selectedPaladin}
                                    onChange={(event) => handleSelect(event, 0, 'Paladin')}
                                    className="w-full border rounded py-2 px-2 shadow-md hover:shadow-lg transition-shadow duration-30"
                                >
                                    {paladins.map(paladin => (
                                        <option key={paladin.name} value={paladin.name}>{paladin.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td className="border px-4 py-2">&nbsp;</td>
                            <td className="border px-4 py-2">&nbsp;</td>
                            <td className="border px-4 py-2">
                                <input
                                    type="number"
                                    className="block w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                    min="0.0"
                                    max="1.5"
                                    step="0.05"
                                    value={selectedCuts['Paladin']?.[0] || startingPercentages['Paladin'][0]}
                                    onChange={(event) => handleCutChange(event, 0, 'Paladin')}
                                />
                            </td>
                            <td className="border px-4 py-2">{findCut('Paladin', 0)}</td>
                        </tr>
                        <tr className="bg-gray-100">
                            <td className="border px-4 py-2 font-bold">Top Spender</td>
                            <td className="border px-4 py-2">
                                <select
                                    onChange={(event) => handleDeductionChange(0, 'name', event.target.value)}
                                    className="w-full border rounded py-2 px-2 shadow-md hover:shadow-lg transition-shadow duration-30"
                                >
                                    <option value="">Select a Character</option>
                                    {characters
                                        .filter(character => !['NPC', 'Pet'].includes(character.type))
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((character, index) => (
                                            <option key={index} value={character.name}>{character.name}</option>
                                        ))}
                                </select>
                            </td>
                            <td className="border px-4 py-2">&nbsp;</td>
                            <td className="border px-4 py-2">&nbsp;</td>
                            <td className="border px-4 py-2">
                                <input
                                    type="number"
                                    className="block w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                    min="0.0"
                                    max="1.5"
                                    step="0.05"
                                    value={selectedCuts['Top Spender']?.[0] || startingPercentages['Top Spender'][0]}
                                    onChange={(event) => handleCutChange(event, 0, 'Top Spender')}
                                />
                            </td>
                            <td className="border px-4 py-2">{findCut('Top Spender', 0)}</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
            <div className="col-span-3">
                <table className="w-full shadow-md">
                    <thead>
                    <tr className="bg-zinc-200">
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Deduction %</th>
                        <th className="px-4 py-2">Deduction</th>
                    </tr>
                    </thead>
                    <tbody>
                    {deductions.map((deduction, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                            <td className="border px-4 py-2">
                                <select
                                    className="w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                    value={deduction.name}
                                    onChange={(event) => handleDeductionChange(index, 'name', event.target.value)}
                                >
                                    <option value="">Select a Character</option>
                                    {characters
                                        .filter(character => !['NPC', 'Pet'].includes(character.type))
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((character, index) => (
                                            <option key={index} value={character.name}>{character.name}</option>
                                        ))}
                                </select>
                            </td>
                            <td className="border px-4 py-2">
                                <input
                                    type="number"
                                    className="w-full border rounded py-1 px-3 shadow-md hover:shadow-lg transition-shadow duration-30"
                                    min="0"
                                    max="100"
                                    value={deduction.percentage}
                                    onChange={(event) => handleDeductionChange(index, 'percentage', event.target.value)}
                                />
                            </td>
                            <td className="border px-4 py-2">
                                {deduction.newCut}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button onClick={handleAddDeduction}
                        className="bg-zinc-200 hover:bg-zinc-300 text-black font-semibold py-2 px-4 rounded border border-black mt-3.5 shadow-md hover:shadow-lg transition-shadow duration-30">
                    Add Deduction
                </button>
            </div>
            <div className="col-span-2 space-y-4">
                <table className="w-full shadow-md" ref={tableRef}>
                    <thead>
                    <tr className="bg-zinc-200">
                        <th className="px-4 py-2 w-3/7">Player</th>
                        <td className="px-4 py-2 w-1/7"></td>
                        <th className="px-4 py-2 w-3/7 break-keep">Total Cut</th>
                    </tr>
                    </thead>
                    <tbody>
                    {calculateFinalCuts().map((player, index) => (
                        <tr key={`total-cuts-${index}`} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                            <td className="border px-4 py-2">{player.name}</td>
                            <td className="border px-4 py-2"></td>
                            <td className="border px-4 py-2">{player.finalCut}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button onClick={handleCopyToClipboard}
                        className="bg-zinc-200 hover:bg-zinc-300 text-black font-semibold py-2 px-4 rounded border border-black mt-3.5 shadow-md hover:shadow-lg transition-shadow duration-30">
                    Export to Gargul
                </button>
                <button onClick={handleExportToPNG}
                        className="bg-gray-200 hover:bg-gray-300 text-black font-semibold py-2 px-4 rounded border border-black shadow-md hover:shadow-lg transition-shadow duration-200 ml-2">
                    Export to PNG
                </button>
                {popupMessage && (
                    <div
                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 shadow-lg border-black border-bl rounded-lg bg-green-300">
                        <span className="block text-center font-bold">{popupMessage}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

function getRankLabel(measurement, index) {
    return {
        'healing': `Healer ${index + 1}`,
        'damage-done': `DPS ${index + 1}`,
        'damage-taken': `Tank ${index + 1}`,
        'FF': `Faerie Fire`,
        'CoR': `Curse of Recklessness`,
        'CoE': `Curse of Elements`,
        'IEA': 'Expose Armor',
        'MC': `Mind Control ${index + 1}`
    }[measurement || "damage-done"]
}

export default Datatable;
